import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownProps } from 'semantic-ui-react';

import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as ThemeDark } from '../../../../../../images/theme/ThemeDark.svg';
import { ReactComponent as ThemeLight } from '../../../../../../images/theme/ThemeLight.svg';
import { ReactComponent as ThemeSystem } from '../../../../../../images/theme/ThemeSystem.svg';

import { getEnabledLangOptions } from '../../../Settings/settingsOptions';
import { isProduction } from '../../../../../../setup/config';
import LangSelect from '../../../../../components/LangSelect/LangSelect';

import authStore from '../../../../../../store/authStore';

import AppContext from '../../../../../../contexts/AppContext';

import { TThemeSetting } from '../../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import useSaveUserPreferences from '../../../../../../utils/mutations/useSaveUserPreferences';
import { useFillContextsFromPreferences } from '../../../../../../utils/hooks/preferences/useFillContextsFromPreferences';

import styles from './SettingsModule.module.scss';

interface platformSettingsProps {
	settings: any;
	setOpenSettings: (v: boolean) => void;
	handleCloseModal: (v: boolean) => void;
}

const PlatformSettings: React.FC<platformSettingsProps> = ({ settings, setOpenSettings, handleCloseModal }) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const { mutate: savePreferences } = useSaveUserPreferences();
	const [theme, setTheme] = useState(appContext.userPreferences!.user_prefs.platform.theme._type);
	const fillContextsFromPreferences = useFillContextsFromPreferences();

	const isJapanAccount = authStore.use.isJapanAccount();

	const enabledLangOptions = getEnabledLangOptions(isProduction && isJapanAccount ? ['ja'] : []);

	const handleLanguage = async (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
		const initialSettingLanguage = appContext.userPreferences!.user_prefs.platform.lang;
		if (typeof value === 'string') {
			localStorage.setItem('language', value);
			await savePreferences();

			if (initialSettingLanguage._name !== value) {
				window.location.reload();
			}

			fillContextsFromPreferences(appContext.userPreferences!);
		}
	};

	const handleThemeSwitch = async (value: string) => {
		let invertedSetting: TThemeSetting;

		switch (value) {
			case 'Light':
				appContext.userPreferences!.user_prefs.platform.theme = { _type: 'Light' };

				setTheme('Light');
				invertedSetting = { _type: 'Light' };
				break;
			case 'System':
				appContext.userPreferences!.user_prefs.platform.theme = { _type: 'System' };
				setTheme('System');

				invertedSetting = { _type: 'System' };
				break;
			case 'Dark':
			default:
				appContext.userPreferences!.user_prefs.platform.theme = { _type: 'Dark' };

				setTheme('Dark');
				invertedSetting = { _type: 'Dark' };
				break;
		}
		await savePreferences();
		fillContextsFromPreferences(appContext.userPreferences!);
		appContext.userPreferences!.user_prefs.platform.theme = invertedSetting;
		savePreferences();
	};

	return (
		<div className={styles.platformTab}>
			<div className={styles.closeIcon} onClick={handleCloseModal}>
				<FontAwesomeIcon icon={['fal', 'xmark-large']} className={styles.icon} />
			</div>
			<div className={styles.profileSetingsHeader}>
				<div className={styles.platformTabName}>{t('wtr:PLATFORM')}</div>
			</div>
			<div className={styles.profileSetingsContent}>
				<div className={styles.settingsPairs}>
					<div className={styles.settingsPairsName}>{t('en:LANGUAGE')}</div>

					<div className={cn(styles.settingsPairsImgDropdown, styles.dropdownMenuLangWrapper)}>
						<LangSelect
							defaultLang={settings.language}
							onChange={handleLanguage}
							className={cn(styles.dropdownMenu, styles.dropdownMenuLang)}
							selection={true}
							disabled={appContext.isJapanAccount}
							customIcon={true}
						/>
					</div>
				</div>
				<div className={cn(styles.settingsPairsTheme, styles.borderBottom)}>
					<div className={cn(styles.settingsPairsName, styles.additionalPadding)}>{t('en:THEME')}</div>
					<div className={styles.settingsThemeWrapper}>
						<div
							className={cn(styles.singlethemeWrapper, theme === 'Light' ? styles.active : styles.inactive)}
							onClick={() => handleThemeSwitch('Light')}
						>
							<div className={styles.themeImage}>
								<ThemeLight />
							</div>
							<div className={styles.themeRadioWrapper}>
								<input value="Light" type="radio" checked={theme === 'Light'} onChange={() => {}} />
								<label className={styles.themeRadioName}>{t('en:APP_THEME_LIGHT')}</label>
							</div>
						</div>
						<div
							className={cn(styles.singlethemeWrapper, theme === 'Dark' ? styles.active : styles.inactive)}
							onClick={() => handleThemeSwitch('Dark')}
						>
							<div className={styles.themeImage}>
								<ThemeDark />
							</div>
							<div className={styles.themeRadioWrapper}>
								<input value="Dark" type="radio" checked={theme === 'Dark'} onChange={() => {}} />
								<label className={styles.themeRadioName}>{t('en:APP_THEME_DARK')}</label>
							</div>
						</div>
						<div
							className={cn(styles.singlethemeWrapper, theme === 'System' ? styles.active : styles.inactive)}
							onClick={() => handleThemeSwitch('System')}
						>
							<div className={styles.themeImage}>
								<ThemeSystem />
							</div>
							<div className={styles.themeRadioWrapper}>
								<input value="System" type="radio" checked={theme === 'System'} onChange={() => {}} />
								<label className={styles.themeRadioName}>{t('en:SETTINGS_SYSTEM')}</label>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.settingsPairs}>
					<div className={styles.settingsPairsName}>{t('wtr:CURRENT_VERSION')} </div>
					<div className={styles.settingsPairsPhone}>{process.env.REACT_APP_VERSION}</div>
				</div>
			</div>
		</div>
	);
};

export default PlatformSettings;
